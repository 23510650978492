import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";

import baseUrl, {
  authentication,
  deductPoints,
  updateFirstName,
  updateLastName,
  updatePoints,
  bankAverage,
  addBankAverage,
  roundAverage,
  isOhrHachaim,
} from "../Utils";

class Bank extends React.Component {
  reset = () => {
    this.setState(
      {
        studentData: [],
        date: "",
        formattedDate: "",
      },
      this.setClass()
    );
  };
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData: [],
      newSubcategoryOpen: false,
      studentColumns: [
        {
          dataField: "first_name",
          text: "First Name",
          sort: true,
          row: 1,
        },
        {
          dataField: "last_name",
          text: "Last Name",
          sort: true,
          row: 1,
        },
        {
          dataField: "bank_account_number",
          text: "Bank account number",
          sort: true,
          editable: false,
        },
        {
          dataField: "points",
          text: isOhrHachaim ? "YEAR TO DATE" : "Points",
          sort: true,
          row: 1,
          editable: true,
          formatter: (cell, row) => {
            if (row.tests != "" || row.retake != "" || row.marks < -4) {
              return (
                <span
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "black",
                    padding: "0px",
                  }}
                >
                  <strong style={{ color: "white" }}>{cell} </strong>
                </span>
              );
            }
            return <span>{cell}</span>;
          },
        },
        {
          dataField: "marks",
          text: "Marks",
          sort: true,
          editable: false,
        },
        {
          dataField: "tests",
          text: "Missing Tests",
          sort: true,
          editable: false,
        },
        {
          dataField: "retake",
          text: "Missing Retakes",
          sort: true,
          editable: false,
        },

        {
          dataField: "grade",
          text: "Grade",
          sort: true,
          editable: false,
        },
        {
          dataField: "class",
          text: "Class",
          sort: true,
          editable: false,
        },
        {
          dataField: "percentOfModel",
          text: "%",
          sort: true,
          editable: false,
        },
      ],
    };
    if (bankAverage) {
      this.state.studentColumns.push({
        dataField: "average",
        text: "Average",
        sort: true,
        editable: false,
        formatter: (cell, row) => {
          return roundAverage(cell);
        },
      });
    }
    this.setClass();
  }

  setClass() {
    const modelStudentMarks = {};
    fetch(`${baseUrl}/bank`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        res.forEach((item) => {
          if (item.last_name.endsWith("!!") ) {
            modelStudentMarks[item.grade] = item.marks;
          }
        });
        console.log(modelStudentMarks);
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.tests = "";
          item.missingTests.map((test, index) => {
            if (index != 0) {
              item.tests += " | ";
            }
            item.tests += test.topic;
          });
          item.retake = "";
          item.retakes.map((test, index) => {
            if (index != 0) {
              item.retake += " | ";
            }
            item.retake += test.topic;
            item.retake += ` Mark: ${test.mark}`;
          });
          item.percentOfModel = modelStudentMarks[item.grade] ? ((item.marks || 0) / (modelStudentMarks[item.grade] || 0) * 100) + "%" : "";
        }
      );

        if (bankAverage) {
          res = await addBankAverage(res);
        }
        this.setState({ studentData: res });
        console.log(this.state);
      });
  }
  validate = () => {
    if (this.state.formattedDate == "") {
      alert("Please Select A Date.");
      return false;
    }
    return true;
  };
  submit = () => {
    if (this.validate()) {
      let students = {
        students: this.state.studentData,
        date: this.state.formattedDate,
      };
      students = JSON.stringify(students);

      let formData = new FormData();
      formData.append("data", students);
      fetch(`${baseUrl}/redeem-rewards`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";

          if (text == "successful") {
            this.reset();
          } else {
            alert("error" + text);
          }
        });
    }
  };

  getStudents = () => {
    var url = new URL("http://ohrhatorah.appsenses.com/students.php");
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        // console.log(res)
        res.forEach((a) => {
          a.id = parseInt(a.id);
          a.points = parseInt(a.points);
        });
        this.setState({ subcategories: res });
      });

    // this.setState({isSubcategoryDialogOpen:true})
  };

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Bank</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            ></div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              {/* <Button style={{float:"right"}} onClick={()=>{this.submit()}}>
                                    Save
                                </Button>*/}
            </div>
          </div>

          {this.state.studentData != undefined ? (
            <Table
              paginationDownward={true}
              delete={false}
              editFunc={(oldValue, newValue, row, column) => {
                {
                  if (oldValue != newValue) {
                    if (column.dataField == "points") {
                      let points = newValue - oldValue;
                      deductPoints(row.id, points);
                    }
                    if (column.dataField == "first_name") {
                      updateFirstName(row.id, newValue);
                    }
                    if (column.dataField == "last_name") {
                      updateLastName(row.id, newValue);
                    }
                  }
                }
              }}
              columns={this.state.studentColumns}
              data={this.state.studentData}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default Bank;
