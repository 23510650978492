import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "./Table";
import styles from "./marquee.css";

import baseUrl, {
  authentication,
  bankAverage,
  addBankAverage,
  roundAverage,
  isOhrHachaim,
} from "../Utils";

import { Helmet } from "react-helmet";

class Marquee extends React.Component {
  constructor(props) {
    super(props);
    // this.getStudents()
    this.state = {
      studentData: [],
      newSubcategoryOpen: false,

      studentColumns: [
        {
          dataField: "bank_account_number",
          text: "Bank account number",
          sort: true,
          editable: false,
          formatter: (cell, row) => {
              return (
                  <strong 
                  style={{
                      fontSize:"1.7em"
                    }}
                >{cell} </strong>
              );
            }
        },
        {
          dataField: "points",
          text: isOhrHachaim ? "YEAR TO DATE" : "Points",
          sort: true,
          row: 1,
          editable: false,
          formatter: (cell, row) => {
            if (row.tests != "" || row.retake != "" || row.marks < -4) {
              return (
                <span
                  className={"strikeout"}
                  style={{
                    width: "100%",
                    height: "100%",
                    background: "black",
                    padding: "0px",
                  }}
                >
                  <strong style={{ color: "white" }}>{cell} </strong>
                </span>
              );
            }
            return <span style={{ fontSize: "1.7em" }}>{cell}</span>;
          },
        },
        {
          dataField: "marks",
          text: "Marks",
          sort: true,
          editable: false,
        },
        {
          dataField: "tests",
          text: "Missing Tests",
          sort: true,
          editable: false,
        },
        {
          dataField: "retake",
          text: "Missing Retakes",
          sort: true,
          editable: false,
        },
      ],
    };


    this.setClass();
    setInterval(() => {
        this.setClass();
    }, (60000 * 60));
    
  }

  createEmptyRow () {
      return {
          bank_account_number: "",
          class: "",
          first_name: "",
          grade: "",
          id: Math.random(1,10),
          last_name: "",
          marks: "",
          missingTests: [],
          points: "",
          retake: "",
          retakes: [],
          tests: ""};
  }

  setClass() {
    fetch(`${baseUrl}/bank`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then(async (res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.points = parseInt(item.points);
          item.tests = "";
          item.missingTests.map((test, index) => {
            if (index != 0) {
              item.tests += " | ";
            }
            item.tests += test.topic;
          });
          item.retake = "";
          item.retakes.map((test, index) => {
            if (index != 0) {
              item.retake += " | ";
            }
            item.retake += test.topic;
          });
        });


        res.unshift(this.createEmptyRow());
        res.unshift(this.createEmptyRow());
        res.unshift(this.createEmptyRow());
        res.unshift(this.createEmptyRow());
        res.unshift(this.createEmptyRow());
        
        this.setState({ studentData: res });
      });
  }

  render() {
    return (
      <div>
        {/*@media print {}*/}
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Rewards</Breadcrumb.Item>
          <Breadcrumb.Item active>Marquee</Breadcrumb.Item>
        </Breadcrumb>

        <div style={{ padding: "15px" }}>
          <div
            className="row"
            style={{ textAlign: "center", paddingBottom: "15px" }}
          >
            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingTop: "10px" }}
            ></div>

            <div
              className="col-sm-12 col-lg-6"
              style={{ paddingRight: "30px", paddingBottom: "10px" }}
            >
              {/* <Button style={{float:"right"}} onClick={()=>{this.submit()}}>
                                    Save
                                </Button>*/}
            </div>
          </div>

          {this.state.studentData != undefined ? (
            <div className="marquee">
            <div className="inner">
            <Table
              defaultSorted={[
                { dataField: "bank_account_number", order: "asc" },
              ]}
              pagination={false}
              switch={false}
              search={false}
              delete={false}
              editable={false}
              editVerify={false}
              selectable={false}
              columns={this.state.studentColumns}
              data={this.state.studentData}
            />
            </div>
            </div>
          ) : (
            ""
          )}
      </div>
      </div>
    );
  }
}
export default Marquee;
